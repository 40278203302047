import { useSelector } from 'react-redux';
import { ROLE_ID_SUPER_ADMIN } from '../constants/user';
import { sortRoles } from '../utils/usersHelper';

const useUserRole = (currentCompanyId) => {
    const userRoles = useSelector(state => state?.user?.info?.userBranchRoles);
    const companyIdFromStorage = useSelector((state) => state.sidebar.companyId);

    let companyId = currentCompanyId ? currentCompanyId : companyIdFromStorage;

    const superAdminRole = userRoles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
    const filteredByCompanyRoles = userRoles?.filter(role => role?.branch?.company?.id === +companyId)?.sort(sortRoles);

    const userRoleId = superAdminRole?.[0]?.role?.id || filteredByCompanyRoles?.[0]?.role?.id;
    const userRoleName = superAdminRole?.[0]?.role?.name || filteredByCompanyRoles?.[0]?.role?.name;

    const userRoleCompanyId = superAdminRole?.[0]?.branch?.company?.id || filteredByCompanyRoles?.[0]?.branch?.company?.id;

    const userRoleCompanyName = superAdminRole?.[0]?.branch?.company?.name || filteredByCompanyRoles?.[0]?.branch?.company?.name;

    const userRoleCompany = superAdminRole?.[0]?.branch?.company || filteredByCompanyRoles?.[0]?.branch?.company;
    
    const isEssential = superAdminRole?.[0]?.role?.essential || filteredByCompanyRoles?.[0]?.role?.essential;

    return { userRoleId, userRoles, userRoleName, userRoleCompanyId, userRoleCompanyName,userRoleCompany, filteredByCompanyRoles, isEssential };
};

export default useUserRole;
