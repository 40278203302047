import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import cn from 'classnames';
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import React from "react";

const EndOfCourseModal = ({
   onClose,
   isOpen,
   title,
   text,
   icon,
   score,
   maxHeight = "540px",
   maxWidth = "450px",
   onResetCourse,
   onLater,
   certificate,
   isFailed,
   certificateModal,
   course,
   popupText,
   goToResult
}) => {

   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         withCross
      >
         <div className={styles.modal}>
            {icon && <img className={styles.icon} src={icon} alt="" />}

            <div className={styles.title}>{title}</div>
            <p className={styles.text}>{ popupText?.length > 0 ? popupText : text}</p>

            {!!score && !isNaN(score) && course?.isShowCourseScores && (
               <div className={styles.scoreBlock}>
                  <span>{t("Your score")}</span>
                  <h2>{score} / 100</h2>
               </div>
            )}

            {isFailed && (
               <p className={cn(styles.text, styles.againLabel)}>Would you like to try again?</p>
            )}

            {isFailed && (
               <div className={styles.btns}>
                  <Button title={t("Later")} onClick={onLater} isBlack />
                  <Button title={t("Retry")} onClick={onResetCourse} />
               </div>
            )}

            {!isFailed && course?.isIssueCertificate && (
               <div className={styles.btns}>
                  <Button onClick={goToResult} title={t('Go to results page')} isBlack/>

                  <Button
                     title={t("View certificate")}
                     onClick={()=> {
                        certificateModal.open();
                        onClose();
                     }}
                  />
               </div>
            )}

            {!isFailed && !course?.isIssueCertificate &&
               <div className={styles.btns}>
                  <Button
                     title={"Got it"}
                     onClick={()=> {
                        onClose();
                     }}
                  />
               </div>
            }


         </div>
      </ModalLayout>
   );
};

export default EndOfCourseModal;
