import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import QuestionItem from "../QuestionItem/QuestionItem";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import testDescIcon from "../../../../assets/images/test_desc_icon.svg";
import arrowRight from "../../../../assets/images/arrow_right.svg";
import arrowLeft from "../../../../assets/images/arrow_left.svg";
import backIcon from "../../../../assets/images/icon_back.svg";
import menuIcon from "../../../../assets/images/menu/list_icon.svg";
import ConfirmModal from "../../../../components/Modals/ConfirmModal/ConfirmModal";

import KnowledgeCheckAPI from "../../../../utils/api/KnowledgeCheckAPI";
import { useSelector } from "react-redux";
import UserAnswerApi from "../../../../utils/api/UserAnswerApi";
import Button from "../../../../components/Button/Button";

import useHandleModal from "../../../../hooks/dom/useHandleModal";
import { parseIsoDuration } from "../../../../utils/coursesHelper";
import CountdownTimer from "../../../../components/CountdownTimer/CountdownTimer";
import IntroductionBlock from "./IntroductionBlock";
import { MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, QUESTIONS_THAT_NEED_EXTRA_CREATION, formatMilliseconds } from "../../../../utils/questionsHelper";
import TestResultsModal from "../../../../components/Modals/TestResultsModal/TestResultsModal";

import cn from "classnames";
import styles from "./styles.module.scss";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_CHECK, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../../../constants/tests";
import { ANSWER_EXTRA_GROUP_ONE } from "../../../../utils/api/serialization_groups";
import { useParams } from "react-router-dom";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../../../utils/usersHelper";

const QuestionsBlock = ({
   questions,
   setQuestions,
   questionsLoading,
   activeTest,
   updatableActiveElement,
   onClickElement,
   handleInputsDataChange,
   questionsDataFromInputs,
   fetchQuestionsData,
   fetchModulesData,
   onNextLesson,
   setIsTestStarted,
   setQuestionsDataFromInputs,
   restartTest,
   modules,
   setUpdatableActiveElement,
   isStatisticsUserCourse,
   setIsLessonsSidebarOpen,
   goToResult
}) => {
   
   const confirmModal = useHandleModal();
   const testPassedModal = useHandleModal();
   const testFailedModal = useHandleModal();
   const testOnCheckModal = useHandleModal();
   const [isToExit, setIsToExit] = useState(false);
   
   const t = useFindTranslationsByString();

   const userId = useSelector((state) => state.user.info.id);

   const params = useParams();
   const navigate = useNavigate();

   const [testScore, setTestScore] = useState("");
   const [timeTestStarted, setTimeTestStarted] = useState(null);
   const [initialTime, setInitialTime] = useState(null);
   const [isActualStarted, setIsActualStarted] = useState(false);
   const [isTimerStopped, setIsTimerStopped] = useState(false);
   const [showIntroBlock, setShowIntroBlock] = useState(false);

   const [isCheckingResults, setIsCheckingResults] = useState(false);

   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
   const [passedQuestionIndexes, setPassedQuestionIndexes] = useState(new Set());

   const handleNextQuestion = () => {
      if (currentQuestionIndex < questions.length - 1) {
         setCurrentQuestionIndex(currentQuestionIndex + 1);
         setPassedQuestionIndexes(
            (prev) => new Set([...prev, currentQuestionIndex])
         );
      }
   };

   const handlePrevQuestion = () => {
      if (currentQuestionIndex > 0) {
         setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
   };

   const handleEnterQuestion = (index) => {
      setCurrentQuestionIndex(index);
   };

   const checkTestsResults = (updatedLesson) => {
      if(!updatedLesson) return;
      setUpdatableActiveElement(updatedLesson);

      setIsCheckingResults(false);
      const currentUserTest = updatedLesson?.userTests?.find(test => test.user.id === userId);

      const successPercantages = +currentUserTest?.successPercentage
      setTestScore(successPercantages);

      if(currentUserTest?.status === TEST_STATUS_ON_CHECK) {
         testOnCheckModal.open();
         return;
      }

      if(currentUserTest?.status === TEST_STATUS_ON_REWORK) {
         testOnCheckModal.open();
         return;
      }

      if(currentUserTest?.status === TEST_STATUS_REJECTED) {
         testFailedModal.open();
         return;
      }

      if(currentUserTest?.status === TEST_STATUS_APPROVED) {
         testPassedModal.open();
      }
   };

   const handlePassed = async (timePassed) => {
      const dataFromInputs = []
      setIsCheckingResults(true);

      const timeEnded = Date.now();

      questionsDataFromInputs.forEach((question, idx) => {
            dataFromInputs.push(
            ...question.answers
               .filter(answer => answer.isChosen) 
               .map(answer => ({
                  question_id: questions[idx]?.id, 
                  ...((questions[idx]?.type === MULTIPLE_CHOICE_ANSWER_CODE 
                     || questions[idx]?.type === ONE_CHOICE_ANSWER_CODE 
                     || questions[idx]?.type === TRUE_FALSE_ANSWER_CODE
                     || questions[idx]?.type === SEQUENCE_ANSWER_CODE)
                     ? {} : (answer?.new_content ? { content: answer?.new_content } : { content: answer?.content })),
                  answer_id: answer.id,
               }))
         );
      });

     const currentUserTest = updatableActiveElement?.userTests.find(test => test.user.id === userId);

     const nextAttempt = +currentUserTest?.passAttemptsCounters?.length + 1;
   
     const timeSpentOnTest = (timeEnded - timeTestStarted);
     const durationString = formatMilliseconds(timeSpentOnTest);

     const dataToSend = {
         user_answers: dataFromInputs,
         attempt: nextAttempt || 1,
         time_spent: durationString
      }


      if(dataToSend?.user_answers?.length === 0 && !timePassed) {
         setIsCheckingResults(false);

         return toast(<ToastMsg text={'Choose at least one answer'} isError/>)
      }

      if(dataToSend?.user_answers?.length === 0 && timePassed) {
         testFailedModal.open()
         setIsCheckingResults(false);
         return
      }

      const res = await new UserAnswerApi().createManyAnswers(dataToSend, ANSWER_EXTRA_GROUP_ONE);

      if(res?.error?.message) {
         setIsCheckingResults(false);
         setIsTimerStopped(true);

         return toast(<ToastMsg text={res?.error?.message} isError/>)
      }
      
      if(res?.success?.data) {
         const isError = res?.success?.data?.some(item => {
               return !!item.error;

            });

         const lastErrorMessage = res.success.data.find(item => item.error)?.error?.message;
         
         if(!isError) {
           const updatedLesson = await fetchModulesData();
            
           if(updatedLesson) {
               checkTestsResults(updatedLesson);
               createManualTestQuesions(updatedLesson, res?.success?.data)
           }
         }

         setIsTimerStopped(true);
         setIsCheckingResults(false);

         if(isError) {
            return toast(<ToastMsg text={lastErrorMessage || 'Something went wrong, try again later'} isError/>);
         } 
      }
   }

   const createManualTestQuesions = async (updatedLesson, updatedUserAnswers) => {
      const manualNeededQuestions = questions?.filter(question => QUESTIONS_THAT_NEED_EXTRA_CREATION.includes(question?.type));

      const neededAnswers = updatedUserAnswers?.filter(answer =>
         manualNeededQuestions.some(manualQuestion =>
           answer?.success?.data?.userAnswer?.question?.id === manualQuestion?.id
         )
       );

      if(manualNeededQuestions?.length > 0) {
         const data = manualNeededQuestions.map(question => ({
            user_id: userId,
            question_id: question?.id,
            user_test_id: updatedLesson?.userTests?.find(test => test.user.id === userId)?.id,
            status: TEST_STATUS_ON_CHECK,
            user_answer_ids: neededAnswers?.filter(ans => ans.success?.data?.userAnswer?.question?.id === question?.id).map(ans => ans?.success?.data?.userAnswer?.id),
            success_percentage: 0
         }))

         const resCreateManyQuestion = await new KnowledgeCheckAPI().createManyTestQuestions(data);
      }
   }

   const findQuestionIndexById = (questions, questionId) => {
      return questions.findIndex(question => question.id === +questionId);
    }

   const onDragEnd = (result) => {
      const { source, destination, type } = result;
      // Check if the draggable was dropped outside a droppable
      if (!destination) return;
      if (
         source.droppableId === destination.droppableId &&
         source.index === destination.index
      ) return;


      const reorderedQuestions = [...questionsDataFromInputs]; 
      const questionIndex = findQuestionIndexById(questions, source.droppableId); 

      if (questionIndex !== -1) {
         const question = reorderedQuestions[questionIndex];
         const answers = [...question.answers];
      
         const sourceAnswerIndex = source.index;
         const destinationAnswerIndex = destination.index;

         const [removedAnswer] = answers.splice(sourceAnswerIndex, 1); // Remove the answer from the source position
         answers.splice(destinationAnswerIndex, 0, removedAnswer); // Insert the answer at the destination position
      
         question.answers = answers; 

         if (question.options) {
            question.answers = answers?.map((answer, idx) => {
               // Find the corresponding answer based on matchingOption
               const correspondingAnswer = question.answers.find(
                 (ans) => ans.matchingOption === idx
               );
             
               return {
                 ...answer,
                 new_content: correspondingAnswer ? correspondingAnswer?.content : answer?.content,
               };
             });
          }

         setQuestionsDataFromInputs(reorderedQuestions); // Update the state with the reordered questions
      }
    };

   useEffect(() => {
      if (activeTest && activeTest?.timeAllowed) {
         const timeObj = parseIsoDuration(activeTest?.timeAllowed);
         setInitialTime(timeObj);
      }
   }, [activeTest]);

   if (questionsLoading) {
      return (
         <div className={styles.loader_wrapper}>
            <Loader />
         </div>
      );
   }

   if(!isActualStarted) {
      return (
        <>
         {isMobileDevice() &&
            <button
               onClick={() => {
                  setIsTestStarted(false);
                  setIsLessonsSidebarOpen(true);
               }}
               className={cn(styles.back_icon, styles.backIcon_int)}
            >
             <img src={isMobileDevice() ? menuIcon : backIcon} alt="" />
          </button>
         }
            <IntroductionBlock 
               isStartNeeded
               welcomeMsg={activeTest.welcomeMessage}
               introMsg={activeTest?.description} 
               onActualStart={()=> {
                  setTimeTestStarted(Date.now());
                  setIsActualStarted(true);
               }}
               initialTime={initialTime}
               setIsTestStarted={setIsTestStarted}
            />
        </>
      )
   }

   return (
      <div className={styles.question_block}>
         <button
            onClick={() => confirmModal.open()}
            className={styles.back_icon}
         >
            <img src={isMobileDevice() ? menuIcon : backIcon} alt="" />
         </button>
         <button
            onClick={() => {
               confirmModal.open();
               setIsToExit(true);
            }}
            className={styles.exitText}
         >
            {t('Exit course')}
         </button>

         {isCheckingResults && 
            <div className="default_loader_wrapper">
               <Loader />
            </div>
         }

         <div className={styles.left}>
            <div className={styles.questions_wrapper}>
               {questions.map((question, index) => (
                  <div
                     key={index}
                     className={cn(
                        styles.question,
                        index === currentQuestionIndex && !showIntroBlock
                           ? styles.active_question
                           : ""
                     )}
                  >
                     <QuestionItem
                        setQuestions={setQuestions}
                        handleInputsDataChange={handleInputsDataChange}
                        question={question}
                        questions={questions}
                        questionIndex={index}
                        onDragEnd={onDragEnd}
                     />
                  </div>
               ))}

               {showIntroBlock && 
                  <IntroductionBlock 
                     welcomeMsg={activeTest.welcomeMessage}
                     introMsg={activeTest?.description} 
                     setIsTestStarted={setIsTestStarted}
                  />
               }
            </div>

            {questions && questions?.length === 0 && (
               <div style={{ textAlign: "center", margin: "150px" }}>
                  {t('There are no data')}
               </div>
            )}

            {!showIntroBlock &&
               <div className={styles.end_lesson_btns}>
                  <Button
                      className={cn(styles.prev, currentQuestionIndex === 0 && styles.disabled)}
                      disabled={currentQuestionIndex === 0}
                      onClick={handlePrevQuestion}
                      image={arrowLeft}
                      isBlack
                      title={t("Previous")}
                  />


                  {currentQuestionIndex !== questions?.length - 1 && (
                     <Button
                        onClick={handleNextQuestion}
                        imageRight
                        image={arrowRight}
                        title={t("Next")}
                     />
                  )}

                  {currentQuestionIndex === questions?.length - 1 && !params?.statisticsUserId && (
                     <Button
                        onClick={()=> handlePassed(false)}
                        imageRight
                        disabled={isCheckingResults}
                        image={arrowRight}
                        title={t("Finish attempt")}
                     />
                  )}
               </div>
            }
         </div>

         <div className={styles.right}>
            <div className={styles.navigation}>
               <h3>{t('Navigation')}</h3>
               <div className={styles.numbers}>
                  <button onClick={()=> setShowIntroBlock(true)} className={styles.number}>
                     <img src={testDescIcon} alt={"test description icon"} />
                  </button>

                  {questions?.map((question, idx) => (
                     <button
                        className={cn(
                           styles.number,
                           currentQuestionIndex === idx && !showIntroBlock ? styles.active : "",
                           {
                              [styles.passed]: passedQuestionIndexes.has(idx),
                           }
                        )}
                        onClick={() => {
                           setShowIntroBlock(false);
                           handleEnterQuestion(idx);
                        }}
                        key={question?.id}
                     >
                        {idx + 1}
                     </button>
                  ))}
               </div>
               {!params?.statisticsUserId && 
                  <Button
                    onClick={()=> handlePassed(false)}
                    disabled={isCheckingResults}
                    isBlack
                    imageRight
                    title={`${t("Finish attempt")}...`}
                 />
               }
            </div>

            <div className={styles.navigation_mobile}>
               {!params?.statisticsUserId && 
                  <Button
                    onClick={()=> handlePassed(false)}
                    disabled={isCheckingResults}
                    isBlack
                    imageRight
                    title={`${t("Finish attempt")}...`}
                 />
               }
            </div>
            
            {activeTest?.timeAllowed && (
               <CountdownTimer
                  handleTimePassed={()=> handlePassed(true)}
                  initialTime={initialTime}
                  isStopTimer={isTimerStopped}
               />
            )}

            <ConfirmModal
               confirmButtonText={t("Confirm")}
               onConfirm={() => {
                  if(isToExit) {
                     navigate('/courses');
                  }

                  setIsTestStarted(false);
                  setIsLessonsSidebarOpen(true);
               }}
               onClose={()=> {
                  setIsToExit(false);
                  confirmModal.close();
               }}
               isOpen={confirmModal.isActive}
               title={t("Are you sure?")}
               subtitle={t("All your answers from the test will be lost")}
               maxHeight={isMobileDevice() ? "270px" : '300px'}
            />

            <TestResultsModal
               goToResult={goToResult}
               key={'passed'}
               action={'passed'}
               confirmButtonText={t("Go to the next step")}
               text={activeTest?.congratulatoryMessage ? activeTest?.congratulatoryMessage : t('You have successfully completed the test and are ready to move on to the next tasks')}
               title={t("Congratulations!")}
               onConfirm={onNextLesson}
               score={testScore || '80'}
               isShowScore={activeTest?.isShowTestScore}
               isOpen={testPassedModal.isActive}
               maxHeight={isMobileDevice() ? "420px" : '400px'}
            />

            <TestResultsModal
               goToResult={goToResult}
               key={'almost'}
               action={'almost'}
               confirmButtonText={t("Ok, back to the main page")}
               text={t("As soon as an administrator checks your test, you will be notified of the result. You will also be able to find verified tests in the 'Knowledge Check' section")}
               title={t("Almost done")}
               onConfirm={()=> setIsTestStarted(false)}
               isOpen={testOnCheckModal.isActive}
               maxHeight={isMobileDevice() ? "320px" : '400px'}
            />

            <TestResultsModal
               goToResult={goToResult}
               key={'failed'}
               action={'failed'}
               confirmButtonText={t("Retry")}
               text={t('You have not scored enough points. Try again!')}
               title={t("Not enough points")}
               onConfirm={restartTest}
               isOpen={testFailedModal.isActive}
               score={testScore}
               isShowScore={activeTest?.isShowTestScore}
               maxHeight={isMobileDevice() ? "420px" : '400px'}
               maxWidth="450px"
            />
         </div>
      </div>
   );
};

export default QuestionsBlock;
