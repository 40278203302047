import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import Button from "../../Button/Button";

import styles from "./styles.module.scss";

import almost_done_icon from "../../../assets/images/almost_done_icon.svg";
import passed_test_icon from "../../../assets/images/passed_test_icon.svg";
import failed_test_icon from "../../../assets/images/failed_test_icon.svg";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import React from "react";

const TestResultsModal = ({
   onClose,
   onConfirm,
   isOpen,
   title,
   text,
   score,
   action,
   confirmButtonText,
   maxHeight = '460px',
   maxWidth = '500px',
   isShowScore,
   goToResult
}) => {

   const t = useFindTranslationsByString();

   const img =
      action === "passed"
         ? passed_test_icon
         : action === "failed"
         ? failed_test_icon
         : almost_done_icon;

   return (
     <ModalLayout
       height={"fit-content"}
       isOpen={isOpen}
       maxWidth={maxWidth}
       maxHeight={maxHeight}
       onClose={onClose}
     >
       <div className={styles.confirm_wrapper}>
         <img className={styles.alert_icon} src={img} alt="" />
         <div className={styles.title}>{title}</div>
         <p className={styles.text}>{text}</p>

         {(action === "passed" || action === "failed") && isShowScore && (
           <div className={styles.score_block}>
             {t("Your score")}
             <span>{score || "0"}%</span>
           </div>
         )}
         <div className={styles.btns}>
           {goToResult &&
             <Button
               onClick={goToResult}
               title={t("Go to results page")}
               isBlack
             />
           }
           <Button title={confirmButtonText} onClick={onConfirm} />
         </div>
       </div>
     </ModalLayout>
   );
};

export default TestResultsModal;
