import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import removeAlertImage from "../../../assets/images/remove_image.svg";
import alertImage from "../../../assets/images/warning_icon.svg";
import Loader from "../../Loader/Loader";
import useFindTranslations from "../../../hooks/useFindTranlsations";

const ConfirmModal = ({ onClose, onCancel = null, height='fit-content', onConfirm, isOpen, title, subtitle, isRemove, confirmButtonText, isLoading, dontShowLoader, maxHeight = "340px", maxWidth = "500px", icon }) => {

   const t = useFindTranslations();

   return (
      <ModalLayout
          height={height}
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         withCross
      >
         <div className={styles.confirm_wrapper}>
            {isRemove && (
                <div className={styles.alert_wrapper}><img
                    className={styles.alert_icon}
                    src={removeAlertImage}
                    alt=""
                /></div>
            )}
            {!isRemove && (
                <div className={styles.alert_wrapper}>
                   <img
                    className={styles.alert_icon}
                    src={icon ? icon : alertImage}
                    alt=""
                />
                </div>
            )}
            <div className={styles.title}>
               <span>{title}</span>
               {subtitle && <div className={styles.info_msg}>{subtitle}</div>}
            </div>
          
            <div className={styles.btns}>
               <Button 
                     title={t?.cancel}
                     onClick={()=> onCancel ? onCancel() : onClose()} isBlack
               />
               <Button
                   disabled={isLoading} 
                   title={confirmButtonText ? confirmButtonText : t?.confirm} 
                   onClick={onConfirm}
                   isHoverPurple
               />
            </div>

            <div className='default_loader_wrapper'>
               {isLoading && !dontShowLoader &&
                  <Loader size={"small"} />
               }
            </div>
         </div>
      </ModalLayout>
   );
};

export default ConfirmModal;
