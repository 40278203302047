import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import shortLogo from "../../../assets/images/avatar.svg";
import deleteRed from "../../../assets/images/delete_red.svg";
import galery from "../../../assets/images/galery.svg";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import AgencyApi from "../../../utils/api/AgencyApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../../utils/usersHelper";
import cn from 'classnames';

const AvatarUploadModal = ({ avatar, setRenderedIconFile, setAvatar, onSaveIcon, onClose, isActive, title, desc, iconEditModal, isLoading, isUploadMobileModalSize, isSidebar, deleteFaviconMobileOpen, isChangeIconLoadingFromParent }) => {

   const userInfo = useSelector((state) => state.user.info);
   const [drag, setDrag] = useState(false);
   const [isChangeIconLoading, setIsChangeIconLoading] = useState(false);
   const [fileName, setFileName] = useState('');

   const t = useFindTranslationsByString();

   const fileLoadHandler = (e) => {
      e.preventDefault();
    
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
    
        const reader = new FileReader();
    
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
    
          img.onload = () => {
            // Set canvas dimensions
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 800;  // Set max width or height for compression
            const scaleSize = MAX_WIDTH / img.width;
            canvas.width = MAX_WIDTH;
            canvas.height = img.height * scaleSize;
    
            // Draw image to canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
            // Adjust quality if needed (0.7 here is 70% quality)
            canvas.toBlob((blob) => {
              const compressedFile = new File([blob], file.name, { type: file.type });
              setAvatar(compressedFile);
              setRenderedIconFile(URL.createObjectURL(blob)); // Preview the compressed image
    
              if (isSidebar) {
                onSave();
              }
            }, "image/jpeg", 0.7);
          };
        };
    
        reader.readAsDataURL(file);
      }
    };
    

   const dragStartHandler = (e) => {
      e.preventDefault();
      setDrag(true);
   };

   const dragLeaveHandler = (e) => {
      e.preventDefault();
      setDrag(false);
   };

   const dropHandler = (e) => {
      e.preventDefault();
      
      const files = [...e.dataTransfer.files];

      setFileName(files[0]?.name)
      if(files[0]) setAvatar(files[0])

       // for show icons straign away
       const reader = new FileReader();
      
       reader.onload = () => {
          setRenderedIconFile(reader.result);
       };
        if(files[0]) {
          reader.readAsDataURL(files[0]);
        }
   };

   const onSave = () => {
      if (!avatar) {
         return toast(<ToastMsg text="Add an icon, please" isError/>);
      }

      if(isSidebar) {
         iconEditModal.open()
        
      } else {
         onSaveIcon();
      };
   }

   const isNoAvatar = userInfo?.avatar === null;
   
   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={isMobileDevice() ? "190px" : "400px"}
         maxWidth={"500px"}
         onClose={onClose}
         isUploadMobileModalSize={isUploadMobileModalSize}
         withCross
      >
         <div className={styles.popup_upload_container_mob}>
            <p className={styles.settings_logo_title}>{title}</p>
            <label className={styles.drag_drop_image_button}>
               <input onChange={fileLoadHandler} type="file" accept="image/*" />
               <img src={galery} alt="" />
                  {t("Upload file")}
            </label>

            <button
               disabled={isChangeIconLoadingFromParent || isNoAvatar}
               onClick={deleteFaviconMobileOpen}
               type="button"
               className={cn(isChangeIconLoadingFromParent || isNoAvatar ? styles.button_disabled : '', styles.button_delete_image)}
            >
               <img src={deleteRed} alt="" />
               <span>{t("Delete image")}</span>
            </button>
         </div>

         <div className={cn(styles.popup_upload_container, styles.popup_upload_container_first)}>
            <div className={styles.settings_logo_header}>
               <span
                  className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
               />
               <div>
                  <p className={styles.settings_logo_title}>{title}</p>
                  <p className={styles.settings_logo_description}>{desc}</p>
               </div>
            </div>
            <div
               onDragStart={dragStartHandler}
               onDragOver={dragStartHandler}
               onDragLeave={dragLeaveHandler}
               onDrop={dropHandler}
               className={styles.upload_image_box}
            >
               <div
                  className={`${styles.drag_drop_image_box}
                              ${drag ? styles.drag : ""}`}
               >
                  <div className={styles.drag_drop_image_text}>
                     <label className={styles.drag_drop_image_button}>
                        <input onChange={fileLoadHandler} type="file" accept="image/*" />
                        {t("Upload file")}
                     </label>
                     {t('or')} Drag & Drop
                  </div>
                  <div>{fileName}</div>
               </div>
            </div>
            <div className={styles.popup_button_grid}>
               <button
                  onClick={onClose}
                  type="button"
                  className={styles.button_popup_cancel}
               >
                  {t('Cancel')}
               </button>
               <button
                type="button" 
                className={styles.button_popup_save}
                onClick={onSave}
                disabled={isLoading}
               >
                  {t('Save changes')}
               </button>
            </div>

            {isLoading && 
               <div className="default_loader_wrapper">
                  <Loader size={"small"} />
               </div>
            }
         </div>
         
      </ModalLayout>
   );
};

export default AvatarUploadModal;
