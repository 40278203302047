import React from "react";
import Button from "../../../components/Button/Button";
import saveImg from "../../../assets/images/symbols/save.svg";
import useFindTranslationByString from "../../../hooks/useFindTranslationsByString";

import styles from "./styles.module.scss";

const RightsSubheader = ({ onSave, isUpdateLoading, updatedProgress, inputsData }) => {
   const t = useFindTranslationByString();


   const newValuesArray = Object?.entries(inputsData)?.filter(([_, value]) => value?.status === "new")?.map(([key, value]) => ({ name: key, ...value }));

   const getButtonTitle = (isLoading, updatedProgress, defaultTitle, loadingTitle) => {
      if (isLoading) {
         return updatedProgress
            ? `${loadingTitle}... ${updatedProgress}%`
            : loadingTitle;
      }

      return defaultTitle;
   };

   const title = getButtonTitle(
      isUpdateLoading,
      updatedProgress,
      t("Save changes"),
      t("Loading")
   );

   return (
      <div className={styles.subheader}>
         <div className={styles.left}>
            <div>{t("Rights")}</div>
            <p>
               {t("Here you can edit role capabilities and their permissions")}.
            </p>
         </div>
         <div className={styles.right}>
            <Button
               isHoverPurple
               title={title}
               image={saveImg}
               onClick={onSave}
               disabled={newValuesArray?.length === 0 || isUpdateLoading}
            />
         </div>
      </div>
   );
};

export default RightsSubheader;
